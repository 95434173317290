/* override Bootstrap to work with our modal */
.fixed-top {
  z-index: 1; }

.input-group-prepend .btn,
.input-group-append .btn {
  z-index: auto; }

/* end override */
.nowrap {
  white-space: nowrap; }

.wrap {
  white-space: normal; }

.start-articles-header strong {
  padding-top: 10px; }

.start-article-row + .product-row td {
  border-top-width: 3px;
  padding-top: 30px; }

.v-select .dropdown-toggle::after {
  display: none; }

#order .control-label {
  font-weight: bold; }

.v-select.dropdown .dropdown-toggle::after {
  content: inherit; }

.v-select.dropdown .form-control {
  height: calc(1.65rem + 2px); }

.wrong,
.multiselect.is-invalid > .multiselect__tags,
.vdp-datepicker.is-invalid > .input-group > .form-control,
.v-select.is-invalid .vs__dropdown-toggle {
  border-color: #dc3545; }

.correct,
.multiselect.is-valid > .multiselect__tags,
.vdp-datepicker.is-valid > .input-group > .form-control,
.v-select.is-valid .vs__dropdown-toggle {
  border-color: #28a745; }

.is-invalid .multiselect ~ .invalid-feedback,
.custom-validation .invalid-feedback,
.form-control-plaintext.is-invalid ~ .invalid-feedback,
.btn.disabled ~ .invalid-feedback,
.vdp-datepicker.is-invalid ~ .invalid-feedback,
.v-select.dropdown.is-invalid ~ .invalid-feedback {
  display: inline-block; }

[rel=collapsible] legend {
  cursor: pointer;
  width: auto; }

.button-text {
  line-height: 38px; }

th.subtotal, td.subtotal {
  text-align: right; }

.delivery-row {
  margin-top: 45px; }
  .delivery-row .row-number {
    background: #343a40;
    display: inline-block;
    padding: 5px 0;
    line-height: 1em;
    border-radius: 18px;
    text-align: center;
    color: #fff;
    height: 36px;
    width: 36px;
    margin-right: 10px; }
  .delivery-row .delivery-row-header {
    padding-bottom: 10px; }
    .delivery-row .delivery-row-header h4 {
      display: flex; }
    .delivery-row .delivery-row-header .delivery-select-holder {
      flex: 1; }
      .delivery-row .delivery-row-header .delivery-select-holder .dropdown.v-select input.form-control {
        height: 36px; }
    .delivery-row .delivery-row-header .row-number {
      margin-top: 5px; }
    .delivery-row .delivery-row-header a.add-link {
      font-size: 0.8em;
      color: #555;
      margin-left: 10px;
      display: inline-block;
      text-align: left;
      width: 300px;
      padding-top: 10px; }
    .delivery-row .delivery-row-header .btn-group {
      height: 38px; }
  .delivery-row .product-row {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #fafafa; }
    .delivery-row .product-row:nth-child(2n + 0) {
      background-color: #fff; }
    .delivery-row .product-row:last-child {
      background-color: #f0f0f0; }
    .delivery-row .product-row .product-sizes th {
      padding: 0;
      text-align: center;
      width: 12.5%;
      border: 0; }
    .delivery-row .product-row .product-sizes td {
      padding: 0; }
      .delivery-row .product-row .product-sizes td input.form-control {
        padding: 0.375rem;
        text-align: center; }
      .delivery-row .product-row .product-sizes td input[type=number]::-webkit-inner-spin-button,
      .delivery-row .product-row .product-sizes td input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; }
    .delivery-row .product-row > .row > .col-3 {
      padding-top: 15px;
      padding-bottom: 15px;
      border-right: 1px #ddd solid; }
    .delivery-row .product-row > .row > .col-9 {
      padding-top: 15px;
      padding-bottom: 15px; }
  .delivery-row .sub-total {
    width: 200px; }

.enrichment-row,
.start-article-row,
.extra-cost-row {
  width: 100%; }
  .enrichment-row .row-number,
  .start-article-row .row-number,
  .extra-cost-row .row-number {
    background: #687380;
    border-radius: 14px;
    height: 28px;
    width: 28px; }
  .enrichment-row .value-cell,
  .start-article-row .value-cell,
  .extra-cost-row .value-cell {
    width: 120px; }
  .enrichment-row .remove-cell,
  .start-article-row .remove-cell,
  .extra-cost-row .remove-cell {
    width: 130px;
    text-align: right; }

.extra-cost-row > .value-cell {
  width: 240px; }

input[type='number'].form-control-plaintext {
  -moz-appearance: textfield; }

input[type=number].form-control-plaintext::-webkit-outer-spin-button,
input[type=number].form-control-plaintext::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

#embroidery-preview-deck .card {
  min-width: 25%;
  margin-bottom: 20px; }
