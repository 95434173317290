body {
  padding-top: 80px;
  padding-bottom: 20px;
  width: 100%;
  height: 100%; }

/* Wrapping element */
/* Set some basic padding to keep content from hitting the edges */
.body-content {
  padding-left: 15px;
  padding-right: 15px; }

/* Carousel */
.carousel-caption p {
  font-size: 20px;
  line-height: 1.4; }

/* Make .svg files in the carousel display properly in older browsers */
.carousel-inner .item img[src$=".svg"] {
  width: 100%; }

/* QR code generator */
#qrCode {
  margin: 15px; }

/* Hide/rearrange for smaller screens */
@media screen and (max-width: 767px) {
  /* Hide captions */
  .carousel-caption {
    display: none; } }

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.block-link {
  display: inline-block;
  width: 100%;
  height: 100%; }

.block-link .without-proof {
  display: inline-block;
  width: 100%;
  height: 100%; }

tr.order-row-details.even td,
tr.order-row-details.even + tr td {
  background-color: #f9f9f9; }

tr.order-row-details.success td,
tr.order-row-details.success + tr td {
  background-color: #dff0d8; }

tr.order-row-details.even:hover td,
tr.order-row-details.even + tr:hover td {
  background-color: #f5f5f5; }

tr.order-row-details.success:hover td,
tr.order-row-details.success + tr:hover td {
  background-color: #d0e9c6; }

tr.late-order td:first-child,
tr.ext-late-order td:first-child {
  background-color: #faf2cc; }

tr.our-late-order td:first-child {
  background-color: #f2dede !important; }

.preparation-order-row-form .row {
  margin-bottom: 10px; }

.row.flash {
  margin-top: 20px; }

.operator-login-bgr {
  min-height: 100%;
  box-sizing: border-box;
  padding-bottom: 100px;
  height: 87%; }

.form-signin .login-container {
  height: 100%; }

.login-pos {
  margin-top: 12%; }

.form-signin .image-bgr {
  /* FIXME: Nice photo? */
  background-position: center;
  background-size: cover;
  padding-bottom: 0px;
  min-height: 700px; }

.form-signin {
  max-width: 420px;
  padding: 30px 38px 30px;
  margin: 0 auto;
  background-color: #eee;
  border: 1px dotted #2b99d4;
  border-radius: 1em; }

.form-signin-white {
  background-color: #f1f1f1;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2), 0 10px 30px 0 rgba(0, 0, 0, 0.3);
  border: 0px dotted #fff;
  border-radius: 1em; }

.form-signin-heading {
  text-align: center;
  margin-bottom: 30px; }

.form-signin p {
  font-size: 16px; }

.form-signin .form-control {
  position: relative;
  font-size: 16px;
  height: 36px;
  padding: 8px;
  margin-top: 0px; }

.form-signin .form-control-sub {
  margin-top: 10px; }

.form-signin .form-group.submit {
  margin-top: 66px; }

.vertical-divider {
  color: #9d9d9d;
  margin-right: 8px;
  margin-left: 8px; }

.nav-divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5; }

.navbar-right {
  margin-right: auto !important; }

.popover-content dl dd {
  margin-bottom: 10px; }

.non-active-info {
  color: #aaa; }

[data-sortable] {
  min-height: 100px; }
  [data-sortable] > .empty-placeholder:only-child {
    display: inline-block;
    height: 100px;
    border: 1px dashed #aaa;
    border-radius: 4px; }
  [data-sortable].disabled > .empty-placeholder {
    text-decoration: line-through; }
  [data-sortable] > .empty-placeholder {
    display: none;
    width: 100%;
    text-align: center;
    padding-top: 10%;
    color: #868e96; }

.selected-workstations {
  counter-reset: workstations; }
  .selected-workstations .counter::before {
    counter-increment: workstations;
    content: counter(workstations) ". "; }
  .selected-workstations .list-group-item:first-of-type:last-of-type .handle {
    display: none; }
  .selected-workstations .list-group-item .handle:before {
    content: "\F07D"; }
  .selected-workstations .list-group-item:first-of-type .handle:before {
    content: "\F175"; }
  .selected-workstations .list-group-item:last-of-type .handle:before {
    content: "\F176"; }
  .selected-workstations.disabled .handle {
    display: none; }
  .selected-workstations div:first-of-type {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px; }
  .selected-workstations div:last-of-type {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px; }

.available-workstations-container label {
  margin-bottom: 10px !important; }

.available-workstations .fa-arrows-v {
  display: none; }

.list-group[data-sortable] {
  margin-bottom: 0; }
  .list-group[data-sortable].disabled {
    cursor: not-allowed; }
    .list-group[data-sortable].disabled .list-group-item {
      background-color: #eee; }

.operations .select2-container--bootstrap {
  width: 100% !important; }

.operations .selected-workstations[data-sortable] {
  min-height: 42px; }
  .operations .selected-workstations[data-sortable] > .empty-placeholder:only-child {
    height: 42px;
    padding-top: 3%; }

#filter-workstation .checkbox-inline,
.filter-workstation .checkbox-inline,
.filter-container .checkbox-inline {
  margin-bottom: 10px; }
  #filter-workstation .checkbox-inline:first-child,
  .filter-workstation .checkbox-inline:first-child,
  .filter-container .checkbox-inline:first-child {
    padding-left: 0; }

#quick-filters.filter-expanded .collapse-icon {
  display: inline-block; }

#quick-filters.filter-expanded .expand-icon {
  display: none; }

#quick-filters.filter-collapsed .collapse-icon {
  display: none; }

#quick-filters.filter-collapsed .expand-icon {
  display: inline-block; }

#quick-filters .checkbox,
#quick-filters .checkbox-inline {
  padding-left: 0; }
  #quick-filters .checkbox label,
  #quick-filters .checkbox-inline label {
    font-weight: normal; }

.modal-full .modal-dialog {
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 15px; }

.modal-full .modal-content {
  height: auto;
  min-height: 100%; }

/* Override Bootstrap so we can have tooltips on disabled buttons */
.btn.disabled[data-toggle="tooltip"] {
  pointer-events: initial !important; }

tr.new-order:first-child td {
  border-top-width: 0 !important; }

tr.new-order td {
  border-top-width: 3px !important; }

.text-with-linebreaks {
  white-space: pre-wrap; }

#admin-dashboard a {
  display: block;
  width: 100%; }

#admin-dashboard .dashboard-subrow td:first-child {
  padding-left: 50px; }

.delivery-status {
  float: right;
  margin-right: 5px; }

.delivery-link {
  color: #000000; }
  .delivery-link:hover {
    text-decoration: none; }

.operators-table .popover {
  max-width: none; }

.collapsing-table td.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.error-table-container .checkbox-inline {
  padding-left: 0;
  margin-bottom: 20px; }

.layout-table .top-row th {
  vertical-align: top; }

.layout-table .top-row td {
  vertical-align: top;
  padding-top: 30px; }

.order-spacer-row td {
  background-color: white; }

.order-summary-row td {
  background-color: ghostwhite;
  cursor: pointer; }
  .order-summary-row td.regular, .order-summary-row td.inactive {
    background-color: white; }
  .order-summary-row td .order-state-label {
    font-weight: bold;
    margin-right: 5px; }
  .order-summary-row td .order-state-delivery-status {
    margin-left: 10px; }

.order-summary-row.table-success > td {
  background-color: #c3e6cb; }

.collapsing-table tbody tr.new-order.collapsed td {
  border-top-width: 3px; }

.collapsing-table tbody tr.collapsed td {
  border-width: 0; }

/*
    attempt to fix animating collapsing table rows
    https://github.com/angular-ui/bootstrap/issues/4358
    http://plnkr.co/edit/GV9Euz5AJFllik7VhHM5?p=preview
*/
.collapsing-table > tbody > tr.order-detail-row td,
.collapsing-table > tbody > tr.order-spacer-row td,
.collapsing-table > tbody > tr.order-detail-row th,
.collapsing-table > tbody > tr.order-spacer-row th {
  padding: 0; }

.order-detail-row th > div > .collapse-cell,
.order-detail-row td > div > .collapse-cell {
  padding: 8px; }

/* end attempt*/
.order-attachment-popover {
  float: left;
  margin-right: 15px; }

.workstation-progress {
  float: right; }

/* Override bootstrap */
/* Bootstrap forces a short horizontal dl, we want it to wrap */
.dl-horizontal dt {
  white-space: normal; }

/* end */
ul.deliveries .popover {
  width: auto;
  max-width: inherit; }

footer > p {
  float: left; }

footer p.license {
  float: right; }

.hr-tiny-margin {
  margin-top: 3px;
  margin-bottom: 3px; }

.button-list li {
  margin-bottom: 5px; }

[rel=select2],
.select2-container {
  width: 100% !important; }

[rel=collapsible] legend {
  cursor: pointer; }

[rel=collapsible].collapsed .fa-caret-right {
  display: inline-block; }

[rel=collapsible].collapsed .fa-caret-down {
  display: none; }

[rel=collapsible] .fa-caret-right {
  display: none; }

[rel=collapsible] .fa-caret-down {
  display: inline-block; }

.select2-results__option[aria-disabled] {
  color: var(--gray);
  color: var(--gray); }

.nowrap {
  white-space: nowrap; }

.delivery-row-number {
  font-size: 1.5rem;
  background: #343a40;
  display: inline-block;
  padding: 5px 0;
  line-height: 1em;
  border-radius: 18px;
  text-align: center;
  color: #fff;
  height: 36px;
  width: 36px;
  margin-right: 10px; }

.address-suggestion {
  cursor: pointer;
  color: #007bff; }

.address-suggestion:hover {
  color: #0056b3; }

.delivery-row-info .delivery-information-warning {
  margin: 5px 2px 0; }

.delivery-row-info .delivery-information-warning:first-of-type {
  margin-top: 30px; }

.tryckhuset-logo.black {
  background: url(144316dbf92940e3d092e52ffd0c8d9a.png); }

.enrichment-article-list li.list-group-item {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0; }

#embroidery-card-deck .card {
  min-width: 20%;
  max-width: 50%;
  margin-bottom: 20px; }

.enrichment-article-list li.list-group-item {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0; }
