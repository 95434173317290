table.order-history blockquote {
  font-size: 12px;
  padding: 10px; }

table.order-history .label-success-th + blockquote {
  border-left: 8px solid #29b343; }

table.order-history .label-waiting-th + blockquote {
  border-left: 8px solid #d95622; }

table.order-history .label-warning-th + blockquote {
  border-left: 8px solid #faf2cc; }

@media (min-width: 1552px) {
  .modal-lg {
    width: 1200px; } }
