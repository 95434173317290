#proof-modal .modal-title,
#preview-modal .modal-title {
  float: left;
  line-height: 34px;
  /* same height as the navigation links */ }

#proof-modal .close,
#preview-modal .close {
  line-height: 34px; }

#proof-modal #proof-modal-navigation,
#proof-modal #preview-modal-navigation,
#preview-modal #proof-modal-navigation,
#preview-modal #preview-modal-navigation {
  text-align: center; }
  #proof-modal #proof-modal-navigation .btn,
  #proof-modal #preview-modal-navigation .btn,
  #preview-modal #proof-modal-navigation .btn,
  #preview-modal #preview-modal-navigation .btn {
    margin-right: 5px; }
  #proof-modal #proof-modal-navigation #proof-nav-download,
  #proof-modal #proof-modal-navigation #preview-nav-download,
  #proof-modal #preview-modal-navigation #proof-nav-download,
  #proof-modal #preview-modal-navigation #preview-nav-download,
  #preview-modal #proof-modal-navigation #proof-nav-download,
  #preview-modal #proof-modal-navigation #preview-nav-download,
  #preview-modal #preview-modal-navigation #proof-nav-download,
  #preview-modal #preview-modal-navigation #preview-nav-download {
    margin-left: 5px; }

#proof-modal canvas#proof-canvas,
#proof-modal canvas#preview-canvas,
#preview-modal canvas#proof-canvas,
#preview-modal canvas#preview-canvas {
  background-color: white;
  display: block;
  margin: 0 auto; }

#proof-modal #proof-container,
#proof-modal #preview-container,
#preview-modal #proof-container,
#preview-modal #preview-container {
  position: relative; }

#proof-modal #proof-container::after,
#proof-modal #preview-container::after,
#preview-modal #proof-container::after,
#preview-modal #preview-container::after {
  content: ' ';
  display: block;
  padding: 0 0 50%; }

#proof-modal #proof-wrapper,
#proof-modal #preview-wrapper,
#preview-modal #proof-wrapper,
#preview-modal #preview-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0; }
