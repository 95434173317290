li.has-unread > a i {
  -webkit-animation: bell-shake 8s ease 3s infinite;
          animation: bell-shake 8s ease 3s infinite;
  color: #5bc0de !important; }

li.notification {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
  position: relative; }
  li.notification:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 5px; }
  li.notification.proof-approved blockquote {
    border-left-color: #3c763d; }
  li.notification.proof-rejected blockquote {
    border-left-color: #a94442; }
  li.notification .notification-author {
    font-weight: bold; }
  li.notification blockquote {
    font-size: 100%;
    margin: 5px 0;
    padding: 10px 20px;
    width: 88%;
    white-space: pre-wrap;
    border-left: 5px solid #eee; }
  li.notification form {
    padding: 3px 20px 10px;
    position: absolute;
    bottom: 0;
    right: 0; }
  li.notification > a {
    white-space: normal;
    min-width: 350px;
    color: #333; }
    li.notification > a:hover {
      color: #333;
      cursor: pointer;
      text-decoration: none; }

#notification-dropdown {
  overflow-y: auto;
  max-height: 90vh;
  min-width: 450px; }

@-webkit-keyframes bell-shake {
  2% {
    -webkit-transform: rotateZ(15deg);
            transform: rotateZ(15deg);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%; }
  4% {
    -webkit-transform: rotateZ(-15deg);
            transform: rotateZ(-15deg);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%; }
  6% {
    -webkit-transform: rotateZ(20deg);
            transform: rotateZ(20deg);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%; }
  8% {
    -webkit-transform: rotateZ(-20deg);
            transform: rotateZ(-20deg);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%; }
  10% {
    -webkit-transform: rotateZ(15deg);
            transform: rotateZ(15deg);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%; }
  12% {
    -webkit-transform: rotateZ(-15deg);
            transform: rotateZ(-15deg);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%; }
  14% {
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%; }
  100% {
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%; } }

@keyframes bell-shake {
  2% {
    -webkit-transform: rotateZ(15deg);
            transform: rotateZ(15deg);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%; }
  4% {
    -webkit-transform: rotateZ(-15deg);
            transform: rotateZ(-15deg);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%; }
  6% {
    -webkit-transform: rotateZ(20deg);
            transform: rotateZ(20deg);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%; }
  8% {
    -webkit-transform: rotateZ(-20deg);
            transform: rotateZ(-20deg);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%; }
  10% {
    -webkit-transform: rotateZ(15deg);
            transform: rotateZ(15deg);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%; }
  12% {
    -webkit-transform: rotateZ(-15deg);
            transform: rotateZ(-15deg);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%; }
  14% {
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%; }
  100% {
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%; } }
