.label-created-th,
.label-registered-th {
  display: inline-block;
  border-left: 8px solid #999;
  padding-left: 10px; }

.label-success-th {
  display: inline-block;
  border-left: 8px solid #29b343;
  padding-left: 10px; }

.label-waiting-th {
  display: inline-block;
  border-left: 8px solid #d95622;
  padding-left: 10px; }

.label-warning-th {
  display: inline-block;
  border-left: 8px solid #faf2cc;
  padding-left: 10px; }

.label-danger-th {
  display: inline-block;
  border-left: 8px solid #a94442;
  padding-left: 10px; }

.label-progress-th {
  display: inline-block;
  border-left: 8px solid #2b99d4;
  padding-left: 10px; }

.label-processed-th {
  display: inline-block;
  border-left: 8px solid #ffeb8e;
  padding-left: 10px; }

.label-cancelled-th {
  display: inline-block;
  border-left: 8px solid #ffffff;
  padding-left: 10px; }
